<template>
  <content-wrapper :title="labels.LBL_TESTIMONIALS" back>
    <template #actions>
      <el-button
        size="small"
        type="success"
        @click="submitForm('testimonialForm')"
      >
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_SAVE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      :rules="rules"
      ref="testimonialForm"
      :model="testimonial"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item prop="image" label="Image">
            <upload-image v-model="testimonial.image" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="client_name" label="Client Name">
            <el-input v-model="testimonial.client_name"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="client_business" label="Client Business">
            <el-input v-model="testimonial.client_business"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item prop="testimonial" label="Testimonial">
            <rich-editor v-model="testimonial.testimonial" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import { mapState } from "vuex";

  import Testimonial from "@/models/testimonial";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import UploadImage from "@/components/UploadImage.vue";
  import RichEditor from "@/components/RichEditor.vue";

  export default {
    name: "AddTestimonial",

    components: {
      ContentWrapper,
      UploadImage,
      RichEditor,
    },

    data() {
      return {
        labels,
        rules,

        testimonial: new Testimonial(),
      };
    },

    computed: {
      ...mapState({ loading: (state) => state.isBusy }),
    },

    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch(
              "testimonial/createTestimonial",
              this.testimonial
            );
          }
        });
      },
    },
  };
</script>

<style></style>
